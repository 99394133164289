<template>
  <div
    id="industry_population_mobility-container"
    :style="{ width, height }"
  ></div>
</template>

<script>
import DataSet from "@antv/data-set";
import { Chart } from "@antv/g2";

export default {
  name: "IndustryPopulationMobilityChart",
  props: ["width", "height"],
  data() {
    return {
      index: 0,
      industry: "制造业",
      keys: [],
      count: 0,
    };
  },
  async mounted() {
    const data = await this.getIndustryPopulationMobilityData();
    // 计算每个柱子的占比
    const ds = new DataSet();
    const dv = ds
      .createView()
      .source(data)
      .transform({
        type: "percent",
        field: "value", // 统计销量
        dimension: "position", // 每年的占比
        groupBy: ["month"], // 以不同产品类别为分组
        as: "percent",
      });

    const chart = new Chart({
      container: "industry_population_mobility-container",
      autoFit: true,
      height: 500,
    });

    chart.data(dv.rows);
    chart.scale({
      percent: {
        min: 0,
        formatter(val) {
          return (val * 100).toFixed(2) + "%";
        },
      },
    });

    chart.legend({
      itemName: {
        style: {
          fill: "#ffffff",
        },
      },
    });

    chart.tooltip({
      shared: true,
      showMarkers: false,
    });

    chart.axis("value", false);

    chart
      .axis("month", {
        label: {
          style: {
            fill: "#ffffff",
            fontSize: 18,
          },
        },
      })
      .axis("percent", {
        label: {
          style: {
            fill: "#ffffff",
            fontSize: 18,
          },
        },
      });

    chart
      .interval()
      .position("month*percent")
      .color("position")
      .adjust("stack")
      .label("percent", function () {
        return {
          position: "middle",
          offset: 0,
          textStyle: {
            fill: "#fff",
            fontSize: 12,
            shadowBlur: 2,
            shadowColor: "rgba(0, 0, 0, .45)",
          },
          formatter(text) {
            return text + "%";
          },
        };
      })
      .style({
        shadowColor: "rgba(255,255,255,0.5)",
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowBlur: 5,
      });

    chart.interaction("active-region");

    chart.render();

    this.timer = setInterval(() => {
      this.update();
    }, 1000 * 10);
  },

  methods: {
    async getIndustryPopulationMobilityData() {
      if (this.values) {
        if (this.count >= this.keys.length) {
          this.count = 0;
        }

        // 有数据返回并++
        if (this.values[this.keys[this.count]].length > 0) {
          this.$emit("change", this.keys[this.count]);
          return this.values[this.keys[this.count++]];
        }

        // 无数据获取下一个
        this.count++;
        return await this.getIndustryPopulationMobilityData();
      }

      this.count = 0;

      try {
        let res = await (
          await fetch(
            `${process.env.VUE_APP_BASE_API}/bigscreen/industryPopulation`
          )
        ).json();
        if (res.code == 200) {
          console.log(res);

          let values = {};

          for (const key in res.data) {
            const element = res.data[key];
            let temp = [];
            element.forEach((item) => {
              temp.push({
                position: "入职",
                month: this.getMonth(item.month),
                value: item.entry_percent,
              });
              temp.push({
                position: "离职",
                month: this.getMonth(item.month),
                value: item.leave_percent,
              });
            });
            values[key] = temp;
          }

          this.keys = Object.keys(values);

          console.log("IndustryPopulationMobility", values);
          this.values = values;
          return await this.getIndustryPopulationMobilityData();
        } else {
          console.error(res.msg);
        }
      } catch (e) {
        console.error(e);
      }
    },

    async update() {
      const data = await this.getIndustryPopulationMobilityData();
      const ds = new DataSet();
      const dv = ds
        .createView()
        .source(data)
        .transform({
          type: "percent",
          field: "value", // 统计销量
          dimension: "position", // 每年的占比
          groupBy: ["month"], // 以不同产品类别为分组
          as: "percent",
        });

      if (this.chart) this.chart.changeData(dv.rows);
    },

    getMonth(num) {
      let monthMap = {
        1: "一月",
        2: "二月",
        3: "三月",
        4: "四月",
        5: "五月",
        6: "六月",
        7: "七月",
        8: "八月",
        9: "九月",
        10: "十月",
        11: "十一月",
        12: "十二月",
      };
      return monthMap[num];
    },
  },

  destroyed() {
    if (this.timer) clearInterval(this.timer);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
