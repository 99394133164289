<template>
    <div id="enterprise_risk_rate-container" :style="{ width, height }"></div>
</template>

<script>
import { Chart, registerInteraction } from "@antv/g2";

export default {
    name: "EnterpriseRiskRateChart",
    props: ["width", "height"],
    data() {
        return {
            index: 0,
            area: '广东省/佛山市/南海区',
            count: 0,
            areaList: ['广东省/佛山市/南海区']
        };
    },
    created() {
        registerInteraction("auto-element-single-selected", {
            start: [
                { trigger: "afterrender", action: "element-single-selected:toggle" },
            ],
        });
    },
    mounted() {
        // this.areaList = await this.getAreaList();
        this.start();
    },

    methods: {
        async getAreaList() {
            try {
                let res = await (await fetch(`${process.env.VUE_APP_BASE_API}/bigscreen/areaList`)).json();
                if (res.code == 200) {
                    console.log(res);
                    return res.data;
                } else {
                    console.error(res.msg);
                }
            } catch (e) {
                console.error(e);
            }
        },

        async start() {
            const data = await this.getEnterpriseRiskRateData();
            // const data = [
            //     { item: "合同签署比率", count: 40, percent: 0.17 },
            //     { item: "保险比率", count: 21, percent: 0.49 },
            //     { item: "资料完善", count: 17, percent: 0.13 },
            //     { item: "自离比率", count: 13, percent: 0.11 },
            //     { item: "出勤比率", count: 9, percent: 0.11 },
            // ];
            const chart = new Chart({
                container: "enterprise_risk_rate-container",
                autoFit: true,
                height: 500,
            });
            this.chart = chart;

            chart.data(data);
            chart.interaction("element-single-selected");
            chart.scale("percent", {
                formatter: (val) => {
                    val = val * 100 + "%";
                    return val;
                },
            });
            chart.legend({
                position: "right",
                itemName: {
                    style: {
                        fill: '#ffffff',
                        fontSize: 16
                    }
                }
            });
            chart.coordinate("theta", {
                radius: 0.75,
                innerRadius: 0.6,
            });
            chart.tooltip({
                showTitle: false,
                showMarkers: false,
                itemTpl:
                    '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
            });
            let setAnnotation = () => {
                chart.annotation().clear(true);
                // 辅助文本
                chart
                    .annotation()
                    .text({
                        position: ["50%", "50%"],
                        content: `${data[this.index].item}`,
                        style: {
                            fontSize: 16,
                            fill: "#fff",
                            textAlign: "center",
                        },
                        offsetY: -20,
                    })
                    .text({
                        position: ["50%", "50%"],
                        content: `${data[this.index].percent}%`,
                        style: {
                            fontSize: 24,
                            fill: "#fff",
                            textAlign: "center",
                        },
                        offsetY: 20,
                    });
                chart.changeData(data);
            };
            let interval = chart
                .interval()
                .adjust("stack")
                .position("percent")
                .color("item")
                .label("percent", (percent) => {
                    return {
                        layout: { type: "overlap" },
                        style: {
                            fill: "#fff",
                            fontSize: 16
                        },
                        content: (data) => {
                            return `${data.item}: ${percent}%`;
                        },
                    };
                })
                .tooltip("item*percent", (item, percent) => {
                    percent = percent + "%";
                    return {
                        name: item,
                        value: percent,
                    };
                }).style({ shadowColor: 'rgba(255,255,255,0.5)', shadowOffsetX: 0, shadowOffsetY: 0 ,shadowBlur: 5});

            setAnnotation();
            chart.interaction("element-active");

            chart.render();

            interval.elements[this.index].setState("selected", true);
            setInterval(() => {
                interval.elements[this.index].setState("selected", false);
                if (this.index < interval.elements.length - 1) this.index++;
                else this.index = 0;
                interval.elements[this.index].setState("selected", true);
                setAnnotation();
            }, 5000);

            this.timer = setInterval(() => {
                this.update();
            }, 1000 * 60 * 60);
        },

        async getEnterpriseRiskRateData() {
            try {
                let res = await (await fetch(`${process.env.VUE_APP_BASE_API}/bigscreen/companyRisk?area=${this.area}`)).json();
                if (res.code == 200) {
                    console.log(res);

                    let values = res.data.map(item => {
                        return { item: item.name, count: 21, percent: item.percent }
                    })

                    console.log('EnterpriseRiskRate', values);
                    return values;
                } else {
                    console.error(res.msg);
                }
            } catch (e) {
                console.error(e);
            }
        },

        async update() {
            const data = await this.getEnterpriseRiskRateData();
            this.area = this.areaList[this.count];
            if (this.count >= this.areaList.length) this.count = 0;
            else this.count++;

            if (this.chart) this.chart.changeData(data);
        },
    },

    destroyed() {
        if (this.timer) clearInterval(this.timer);
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
