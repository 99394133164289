<template>
    <div id="salaryRangeChange-container" :style="{ width, height }"></div>
</template>

<script>
import { Chart, registerInteraction } from "@antv/g2";

export default {
    name: "SalaryRangeChangeChart",
    props: ["width", "height"],
    data() {
        return {
            index: 0,
        };
    },
    created() {
        registerInteraction("auto-element-single-selected", {
            start: [
                { trigger: "afterrender", action: "element-single-selected:toggle" },
            ],
        });
    },
    async mounted() {
        const data = await this.getSalaryRangeChangeData();

        const chart = new Chart({
            container: "salaryRangeChange-container",
            autoFit: true,
            height: 500,
        });

        chart.data(data);
        chart.scale({
            month: {
                range: [0, 1],
            },
            money: {
                nice: true,
            },
        });

        chart.tooltip({
            showCrosshairs: true,
            shared: true,
        });


        chart.axis('month', {
            label: {
                style: {
                    fill: '#ffffff',
                    fontSize: 18
                }
            }
        }).axis('money', {
            label: {
                style: {
                    fill: '#ffffff',
                    fontSize: 18
                }
            }
        });

        chart.legend({
            itemName: {
                style: {
                    fill: '#ffffff',
                    fontSize: 16
                }
            }
        })

        chart.line().position("month*money").size(5).color("type").label("money", {
            style: {
                fill: '#ffffff',
                fontSize: 16
            }
        }).style({ shadowColor: 'rgba(255,255,255,0.5)', shadowOffsetX: 0, shadowOffsetY: 0, shadowBlur: 5 });
        // chart.point().position("month*money").color("type").shape("circle");

        chart.render();
    },
    methods: {
        async getSalaryRangeChangeData() {
            try {
                let res = await (await fetch(process.env.VUE_APP_BASE_API + '/bigscreen/departmentSalary')).json();
                if (res.code == 200) {
                    console.log(res);

                    let values = [];
                    for (let i = 0; i < res.data[0].months.length; i++) {
                        res.data.forEach((item) => {
                            values.push({ month: item.months[i].month, type: item.name, money: item.months[i].money });
                        })
                    }

                    console.log('SalaryRangeChange', values);
                    return values;
                } else {
                    console.error(res.msg);
                }
            } catch (e) {
                console.error(e);
            }
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
