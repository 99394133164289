<template>
    <div id="compony_map-container" :style="{ width, height }"></div>
</template>

<script>
import DataSet from "@antv/data-set";
import { Chart } from "@antv/g2";

export default {
    name: "ComponyMap",
    props: ["width", "height"],
    mounted() {
        fetch("guangdong.geo.json")
            .then((res) => res.json())
            .then(async (mapData) => {
                const chart = new Chart({
                    container: "compony_map-container",
                    autoFit: true,
                });
                chart.tooltip({
                    showTitle: false,
                    showMarkers: false,
                    shared: true,
                });
                // 同步度量
                chart.scale({
                    longitude: {
                        max: 118,
                        min: 109,
                        sync: true,
                    },
                    latitude: {
                        max: 25.5,
                        min: 20,
                        sync: true,
                    },
                });
                chart.axis(false);
                chart.legend(false);

                // 绘制世界地图背景
                const ds = new DataSet();
                const worldMap = ds.createView("back").source(mapData, {
                    type: "GeoJSON",
                });
                const worldMapView = chart.createView();
                worldMapView.data(worldMap.rows);
                worldMapView.tooltip(false);
                worldMapView.polygon().position("longitude*latitude").style({
                    fill: "#fff",
                    stroke: "#ccc",
                    lineWidth: 1,
                });

                // 可视化用户数据
                const userData = await this.getCompanylist();
                // const userData = [
                //     { name: '佛山市', value: 80 },
                //     { name: '汕头市', value: 20 },
                //     { name: '广州市', value: 42.2}
                // ];
                const userDv = ds
                    .createView()
                    .source(userData)
                    .transform({
                        geoDataView: worldMap,
                        field: "name",
                        type: "geo.centroid",
                        as: ["longitude", "latitude"],
                    })
                    .transform({
                        type: "map",
                        callback: (obj) => {
                            obj.trend = obj.value;
                            return obj;
                        },
                    });
                const userView = chart.createView();
                userView.data(userDv.rows);
                userView.scale({
                    trend: {
                        alias: "用户数量",
                    },
                    name: {
                        alias: "名称",
                    },
                });
                userView
                    .point()
                    .position("longitude*latitude")
                    .color("#1890ff88")
                    .tooltip("name*trend")
                    .shape("circle")
                    .size("value", [5, 15])
                    .style({
                        lineWidth: 1,
                        stroke: "#1890ff",
                    })
                    .animate({
                        leave: {
                            animation: "fade-out",
                        },
                    });
                userView.interaction("element-active");

                chart.render();
            });
    },
    methods: {
        async getCompanylist() {
            try {
                let res = await (await fetch(process.env.VUE_APP_BASE_API + '/bigscreen/companys')).json();
                if (res.code == 200) {
                    console.log(res);

                    let values = []
                    res.data.list.forEach(element => {
                        let value = values.find(item => item.name == element.city);
                        if (value) {
                            value.value += element.staff_count;
                        } else {
                            values.push({
                                name: element.city,
                                value: element.staff_count
                            })
                        }
                    });

                    console.log('CompanyMap', values);
                    return values;
                } else {
                    console.error(res.msg);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }
};
</script>

<style scoped lang="scss">
</style>
