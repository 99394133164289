<template>
    <div id="assets_by_month-container" :style="{ width, height }"></div>
</template>

<script>
import { Chart, registerInteraction } from "@antv/g2";

export default {
    name: "AssetsByMonthChart",
    props: ["width", "height"],
    data() {
        return {
            index: 0,
        };
    },
    created() {
        registerInteraction("auto-element-single-selected", {
            start: [
                { trigger: "afterrender", action: "element-single-selected:toggle" },
            ],
        });
    },
    async mounted() {
        const data = await this.getBlockchainData();
        // const data = [
        //     { item: "5月", count: 40, percent: 0.4 },
        //     { item: "6月", count: 21, percent: 0.21 },
        //     { item: "7月", count: 17, percent: 0.17 },
        //     { item: "8月", count: 13, percent: 0.13 },
        //     { item: "9月", count: 9, percent: 0.09 },
        // ];
        const chart = new Chart({
            container: "assets_by_month-container",
            autoFit: true,
            height: 500,
            padding: [12,12,12,12]
        });
        chart.data(data);
        chart.interaction("element-single-selected");
        chart.scale("percent", {
            formatter: (val) => {
                val = val * 100 + "%";
                return val;
            },
        });
        chart.coordinate("theta", {
            radius: 0.75,
            innerRadius: 0.6,
        });
        chart.tooltip({
            showTitle: false,
            showMarkers: false,
            itemTpl:
                '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
        });
        let setAnnotation = () => {
            chart.annotation().clear(true);
            // 辅助文本
            chart
                .annotation()
                .text({
                    position: ["50%", "50%"],
                    content: `${data[this.index].item}`,
                    style: {
                        fontSize: 20,
                        fill: "#fff",
                        textAlign: "center",
                    },
                    offsetY: -20,
                })
                .text({
                    position: ["50%", "50%"],
                    content: `${data[this.index].percent * 100}%`,
                    style: {
                        fontSize: 24,
                        fill: "#fff",
                        textAlign: "center",
                    },
                    offsetY: 20,
                });
            chart.changeData(data);
        };
        let interval = chart
            .interval()
            .adjust("stack")
            .position("percent")
            .color("item")
            .label("percent", () => {
                return {
                    style: {
                        fill: "#fff",
                        fontSize: 16
                    },
                    content: (data) => {
                        return `${data.item}`;
                    },
                };
            })
            .tooltip("item*percent", (item, percent) => {
                percent = percent * 100 + "%";
                return {
                    name: item,
                    value: percent,
                };
            }).style({ shadowColor: 'rgba(255,255,255,0.5)', shadowOffsetX: 0, shadowOffsetY: 0 ,shadowBlur: 5});

        chart.legend(false)


        setAnnotation();
        chart.interaction("element-active");

        chart.render();

        interval.elements[this.index].setState("selected", true);
        setInterval(() => {
            interval.elements[this.index].setState("selected", false);
            if (this.index < interval.elements.length - 1) this.index++;
            else this.index = 0;
            interval.elements[this.index].setState("selected", true);
            setAnnotation();
        }, 5000);
    },

    methods: {
        async getBlockchainData() {
            try {
                let res = await (await fetch(process.env.VUE_APP_BASE_API + '/bigscreen/blockchain')).json();
                if (res.code == 200) {
                    console.log(res);

                    let total = res.data.by_month.reduce((pre, cur) => pre + cur.data, 0)
                    let values = res.data.by_month.map(item => {
                        return { item: this.getMonth(item.month), data: item.data, percent: parseFloat((item.data / total).toFixed(2)) }
                    })

                    console.log('AssetsByMonth', values);
                    return values;
                } else {
                    console.error(res.msg);
                }
            } catch (e) {
                console.error(e);
            }
        },

        getMonth(num) {
            let monthMap = {
                1: '一月',
                2: '二月',
                3: '三月',
                4: '四月',
                5: '五月',
                6: '六月',
                7: '七月',
                8: '八月',
                9: '九月',
                10: '十月',
                11: '十一月',
                12: '十二月',
            }
            return monthMap[num]
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
