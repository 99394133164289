<template>
    <div id="blockchain-container" :style="{ width, height }"></div>
</template>

<script>
import { Chart, registerInteraction } from "@antv/g2";

export default {
    name: "BlockchainChart",
    props: ["width", "height"],
    data() {
        return {
            index: 0,
        };
    },
    created() {
        registerInteraction("auto-element-single-selected", {
            start: [
                { trigger: "afterrender", action: "element-single-selected:toggle" },
            ],
        });
    },
    async mounted() {
        const data = await this.getBlockchainData();
        // const data = [
        //     { year: "2009", type: "已上链数据", number: 7 },
        //     { year: "2009", type: "正在上链数据", number: 3.9 },
        //     { year: "2010", type: "已上链数据", number: 6.9 },
        //     { year: "2010", type: "正在上链数据", number: 4.2 },
        //     { year: "2011", type: "已上链数据", number: 9.5 },
        //     { year: "2011", type: "正在上链数据", number: 5.7 },
        //     { year: "2012", type: "已上链数据", number: 14.5 },
        //     { year: "2012", type: "正在上链数据", number: 8.5 },
        //     { year: "2013", type: "已上链数据", number: 18.4 },
        //     { year: "2013", type: "正在上链数据", number: 11.9 },
        //     { year: "2014", type: "已上链数据", number: 21.5 },
        //     { year: "2014", type: "正在上链数据", number: 15.2 },
        //     { year: "2015", type: "已上链数据", number: 25.2 },
        //     { year: "2015", type: "正在上链数据", number: 17 },
        //     { year: "2016", type: "已上链数据", number: 26.5 },
        //     { year: "2016", type: "正在上链数据", number: 16.6 },
        //     { year: "2017", type: "已上链数据", number: 23.3 },
        //     { year: "2017", type: "正在上链数据", number: 14.2 },
        //     { year: "2018", type: "已上链数据", number: 18.3 },
        //     { year: "2018", type: "正在上链数据", number: 10.3 },
        //     { year: "2019", type: "已上链数据", number: 13.9 },
        //     { year: "2019", type: "正在上链数据", number: 6.6 },
        //     { year: "2020", type: "已上链数据", number: 9.6 },
        //     { year: "2020", type: "正在上链数据", number: 4.8 },
        // ];

        const chart = new Chart({
            container: "blockchain-container",
            autoFit: true,
            height: 500,
        });

        chart.data(data);
        chart.scale({
            year: {
                range: [0, 1],
            },
            number: {
                nice: true,
            },
        });

        chart.tooltip({
            showCrosshairs: true,
            shared: true,
        });


        chart.axis('year', {
            label: {
                style: {
                    fill: '#ffffff',
                    fontSize: 18
                }
            }
        }).axis('number', {
            label: {
                style: {
                    fill: '#ffffff',
                    fontSize: 18
                }
            }
        });

        chart.legend({
            itemName: {
                style: {
                    fill: '#ffffff',
                    fontSize: 16
                }
            }
        })

        chart.line().position("year*number").size(5).color("type").label("number", {
            style: {
                fill: '#ffffff',
                fontSize: 16
            }
        }).style({ shadowColor: 'rgba(255,255,255,0.5)', shadowOffsetX: 0, shadowOffsetY: 0 ,shadowBlur: 5});
        // chart.point().position("year*number").color("type").shape("circle");

        chart.render();
    },
    methods: {
        async getBlockchainData() {
            try {
                let res = await (await fetch(process.env.VUE_APP_BASE_API + '/bigscreen/blockchain')).json();
                if (res.code == 200) {
                    console.log(res);

                    let values = []
                    res.data.by_year.forEach(item => {
                        values.push({ year: item.year, type: "已上链数据", number: item.uploadedAssetCount });
                        values.push({ year: item.year, type: "正在上链数据", number: item.uploadingAssetCount });
                        values.push({ year: item.year, type: "未上链数据", number: item.unUnploadAssetCount });
                    })

                    console.log('Blockchain', values);
                    return values;
                } else {
                    console.error(res.msg);
                }
            } catch (e) {
                console.error(e);
            }
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
