<template>
    <div id="labor_disputes-container" :style="{ width, height }"></div>
</template>

<script>
import { Chart, registerInteraction } from "@antv/g2";

export default {
    name: "LaborDisputesChart",
    props: ["width", "height"],
    data() {
        return {
            index: 0,
            area: '广东省/佛山市/南海区',
            count: 0,
            areaList: ['广东省/佛山市/南海区']
        };
    },
    created() {
        registerInteraction("auto-element-single-selected", {
            start: [
                { trigger: "afterrender", action: "element-single-selected:toggle" },
            ],
        });
    },
    mounted() {
        this.start();
    },
    methods: {
        async start() {
            const data = await this.getLaborDisputesData();
            // const data = [
            //     { position: "桂城", sales: 38 },
            //     { position: "罗村", sales: 52 },
            //     { position: "里水", sales: 61 },
            //     { position: "九江", sales: 145 },
            //     { position: "丹灶", sales: 48 },
            //     { position: "大沥", sales: 38 },
            //     { position: "狮山", sales: 38 },
            //     { position: "西樵", sales: 38 },
            // ];
            const chart = new Chart({
                container: "labor_disputes-container",
                autoFit: true,
                height: 500,
            });
            this.chart = chart;

            chart.data(data);
            chart.scale("sales", {
                nice: true,
                alias: '数量'
            });

            chart.axis('position', {
                label: {
                    style: {
                        fill: '#ffffff',
                        fontSize: 18
                    }
                }
            }).axis('sales', {
                label: {
                    style: {
                        fill: '#ffffff',
                        fontSize: 18
                    }
                }
            });

            chart.tooltip({
                showMarkers: false,
            });
            chart.interaction("active-region");

            chart.interval().position("position*sales").style({ shadowColor: 'rgba(255,255,255,0.5)', shadowOffsetX: 0, shadowOffsetY: 0 ,shadowBlur: 5});

            chart.render();

            this.timer = setInterval(() => {
                this.update();
            }, 1000 * 60 * 60);
        },

        async getLaborDisputesData() {
            try {
                let res = await (await fetch(`${process.env.VUE_APP_BASE_API}/bigscreen/blacklist?area=${this.area}`)).json();
                if (res.code == 200) {
                    console.log(res);

                    let values = res.data.map(item => {
                        return { position: item.town, sales: item.black_num }
                    })

                    console.log('LaborDisputes', values);
                    return values;
                } else {
                    console.error(res.msg);
                }
            } catch (e) {
                console.error(e);
            }
        },

        async update() {
            const data = await this.getLaborDisputesData();
            this.area = this.areaList[this.count];
            if (this.count >= this.areaList.length) this.count = 0;
            else this.count++;

            if (this.chart) this.chart.changeData(data);
        },
    },

    destroyed() {
        if (this.timer) clearInterval(this.timer);
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
