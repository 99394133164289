<template>
  <div id="recruitment_position-container" :style="{ width, height }"></div>
</template>

<script>
import { Chart, registerInteraction } from "@antv/g2";

export default {
  name: "RecruitmentPositionChart",
  props: ["width", "height"],
  data() {
    return {
      index: 0,
      month: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`,
      area: "广东省/佛山市/南海区",
      count: 0,
      areaList: ["广东省/佛山市/南海区"],
    };
  },
  created() {
    registerInteraction("auto-element-single-selected", {
      start: [
        { trigger: "afterrender", action: "element-single-selected:toggle" },
      ],
    });
  },
  async mounted() {
    // this.areaList = await this.getAreaList();
    this.start();
  },
  methods: {
    async getAreaList() {
      try {
        let res = await (
          await fetch(`${process.env.VUE_APP_BASE_API}/bigscreen/areaList`)
        ).json();
        if (res.code == 200) {
          console.log(res);
          return res.data;
        } else {
          console.error(res.msg);
        }
      } catch (e) {
        console.error(e);
      }
    },

    async start() {
      const data = await this.getRecruitmentPositionData();
      // const data = [
      //     { position: "焊工", sales: 38 },
      //     { position: "开料", sales: 52 },
      //     { position: "杂工", sales: 61 },
      //     { position: "打磨", sales: 145 },
      //     { position: "油磨", sales: 48 },
      //     { position: "财务", sales: 38 },
      //     { position: "学徒", sales: 38 },
      //     { position: "锣机", sales: 38 },
      // ];
      const chart = new Chart({
        container: "recruitment_position-container",
        autoFit: true,
        height: 500,
      });
      this.chart = chart;

      chart.data(data);
      chart.scale("sales", {
        nice: true,
        alias: "数量",
      });

      chart
        .axis("position", {
          label: {
            autoRotate: true,
            autoHide: false,
            autoEllipsis: false,
            style: {
              fill: "#ffffff",
              fontSize: 18,
            },
          },
        })
        .axis("sales", {
          label: {
            style: {
              fill: "#ffffff",
              fontSize: 18,
            },
          },
        });

      chart.tooltip({
        showMarkers: false,
      });
      chart.interaction("active-region");

      chart
        .interval()
        .position("position*sales")
        .style({
          shadowColor: "rgba(255,255,255,0.5)",
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowBlur: 5,
        });

      chart.render();

      this.timer = setInterval(() => {
        this.update();
      }, 1000 * 60 * 60);
    },

    async getRecruitmentPositionData() {
      try {
        let res = await (
          await fetch(
            `${process.env.VUE_APP_BASE_API}/bigscreen/jobs?month=${this.month}&area=${this.area}`
          )
        ).json();
        if (res.code == 200) {
          console.log(res);

          let values = res.data.map((item) => {
            return { position: item.position, sales: item.need_number };
          });

          console.log("Recruitment", values);
          return values;
        } else {
          console.error(res.msg);
        }
      } catch (e) {
        console.error(e);
      }
    },

    async update() {
      const data = await this.getRecruitmentPositionData();
      this.area = this.areaList[this.count];
      if (this.count >= this.areaList.length) this.count = 0;
      else this.count++;

      if (this.chart) this.chart.changeData(data);
    },
  },

  destroyed() {
    if (this.timer) clearInterval(this.timer);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
