<template>
  <dv-full-screen-container id="app">
    <div class="large_title">
      <div>思博达和谐劳务数据驾驶舱</div>
      <dv-decoration-5 class="large_title_decoration" />
      <dv-decoration-8 class="large_title_left" />
      <dv-decoration-8 class="large_title_right" :reverse="true" />
      <dv-decoration-3 class="large_title_left_star" />
      <dv-decoration-3 class="large_title_right_star" />
    </div>

    <div class="box">
      <dv-border-box-7 :color="boxColor">
        <div class="flex">
          <div>
            <div class="title">
              <div>{{new Date().getFullYear()}}年度 资产按月份数据报表</div>
              <dv-decoration-2 style="width: 100%; height: 5px" />
            </div>
            <div class="flex">
              <AssetsByMonthChart
                width="100%"
                height="65%"
              ></AssetsByMonthChart>
            </div>
          </div>
          <div>
            <div class="title">
              <div>{{new Date().getFullYear()}}年度 资产数据按数据类型统计报表</div>
              <dv-decoration-2 style="width: 100%; height: 5px" />
            </div>
            <div class="flex">
              <AssetsByTypeChart width="100%" height="65%"></AssetsByTypeChart>
            </div>
          </div>
        </div>
      </dv-border-box-7>
      <div>
        <div class="user_counter" style="height: 30%">
          <div>用户实时数量：</div>
          <div>{{ box2Data }}</div>
        </div>
        <ComponyMap
          width="70%"
          height="70%"
          style="margin: 0 auto"
        ></ComponyMap>
      </div>
      <dv-border-box-7 :color="boxColor">
        <div class="title">
          <div>企业存在风险率</div>
          <dv-decoration-2 style="width: 100%; height: 5px" />
        </div>
        <EnterpriseRiskRateChart
          width="90%"
          height="73%"
          style="margin: 0 auto"
        ></EnterpriseRiskRateChart>
      </dv-border-box-7>
      <dv-border-box-7 :color="boxColor">
        <div class="title">
          <div>区块链人事数据</div>
          <dv-decoration-2 style="width: 100%; height: 5px" />
        </div>
        <BlockchainChart
          width="90%"
          height="73%"
          style="margin: 0 auto"
        ></BlockchainChart>
      </dv-border-box-7>
      <dv-border-box-7 :color="boxColor">
        <div class="title">
          <div>招聘岗位</div>
          <dv-decoration-2 style="width: 100%; height: 5px" />
        </div>
        <RecruitmentPositionChart
          width="90%"
          height="73%"
          style="margin: 0 auto"
        ></RecruitmentPositionChart>
      </dv-border-box-7>
      <dv-border-box-7 :color="boxColor">
        <div class="title">
          <div>劳资纠纷情况</div>
          <dv-decoration-2 style="width: 100%; height: 5px" />
        </div>
        <LaborDisputesChart
          width="90%"
          height="73%"
          style="margin: 0 auto"
        ></LaborDisputesChart>
      </dv-border-box-7>
      <dv-border-box-7 :color="boxColor">
        <div class="title">
          <div>企业所在行业占比</div>
          <dv-decoration-2 style="width: 100%; height: 5px" />
        </div>
        <EnterpriseIndustryChart
          width="90%"
          height="73%"
          style="margin: 0 auto"
        ></EnterpriseIndustryChart>
      </dv-border-box-7>
      <dv-border-box-7 :color="boxColor">
        <div class="title">
          <div>
            行业人口流动率{{
              industryPopulationMobilityKey &&
              `(${industryPopulationMobilityKey})`
            }}
          </div>
          <dv-decoration-2 style="width: 100%; height: 5px" />
        </div>
        <IndustryPopulationMobilityChart
          width="90%"
          height="73%"
          style="margin: 0 auto"
          @change="onIndustryPopulationMobilityKeyChange"
        ></IndustryPopulationMobilityChart>
      </dv-border-box-7>
      <dv-border-box-7 :color="boxColor">
        <div class="title">
          <div>发薪幅度变化</div>
          <dv-decoration-2 style="width: 100%; height: 5px" />
        </div>
        <SalaryRangeChangeChart
          width="90%"
          height="73%"
          style="margin: 0 auto"
        ></SalaryRangeChangeChart>
      </dv-border-box-7>
    </div>
  </dv-full-screen-container>
</template>

<script>
import AssetsByMonthChart from "./components/AssetsByMonthChart.vue";
import AssetsByTypeChart from "./components/AssetsByTypeChart.vue";
import EnterpriseRiskRateChart from "./components/EnterpriseRiskRateChart.vue";
import BlockchainChart from "./components/BlockchainChart.vue";
import RecruitmentPositionChart from "./components/RecruitmentPositionChart.vue";
import LaborDisputesChart from "./components/LaborDisputesChart.vue";
import EnterpriseIndustryChart from "./components/EnterpriseIndustryChart.vue";
// import EducationChart from "./components/EducationChart.vue";
import IndustryPopulationMobilityChart from "./components/IndustryPopulationMobilityChart.vue";
import ComponyMap from "./components/ComponyMap.vue";
import SalaryRangeChangeChart from "./components/SalaryRangeChangeChart.vue";

export default {
  name: "App",
  components: {
    AssetsByMonthChart,
    AssetsByTypeChart,
    EnterpriseRiskRateChart,
    BlockchainChart,
    EnterpriseIndustryChart,
    RecruitmentPositionChart,
    LaborDisputesChart,
    // EducationChart,
    IndustryPopulationMobilityChart,
    ComponyMap,
    SalaryRangeChangeChart
  },
  data() {
    return {
      boxColor: ["#2d6a9c9d"],
      box2Data: "0",
      industryPopulationMobilityKey: "",
    };
  },
  created() {
    // 用户实时数量
    this.getUserCount();
    this.timer = setInterval(() => {
      this.getUserCount();
    }, 1000 * 60);
  },
  methods: {
    async getUserCount() {
      try {
        let res = await (
          await fetch(process.env.VUE_APP_BASE_API + "/bigscreen/userCount")
        ).json();
        if (res.code == 200) {
          console.log(res);
          this.box2Data = res.data.count;
        } else {
          console.error(res.msg);
        }
      } catch (e) {
        console.error(e);
      }
    },

    onIndustryPopulationMobilityKeyChange(e) {
      this.industryPopulationMobilityKey = e;
    },
  },
  destroyed() {
    if (this.timer) clearInterval(this.timer);
  },
};
</script>   

<style lang="scss">
@font-face {
  font-family: titlefont;
  src: url("~@/assets/titlefont.ttf");
}

body {
  margin: 0;
  font-family: "Microsoft YaHei", 微软雅黑, "Microsoft JhengHei", 华文细黑,
    STHeiti, MingLiu;
}

#app {
  background: url("~@/assets/bg.png") no-repeat;
  background-size: contain;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@font-face {
  font-family: titlefont;
  src: url("~@/assets/titlefont.ttf");
}

body {
  margin: 0;
  font-family: "Microsoft YaHei", 微软雅黑, "Microsoft JhengHei", 华文细黑,
    STHeiti, MingLiu;
}

#app {
  background: url("~@/assets/bg.png") no-repeat;
  background-size: contain;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.large_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2vw;
  line-height: 3.5vw;
  color: white;
  font-family: titlefont;
  letter-spacing: 2px;

  .large_title_decoration {
    width: 30vw;
    height: 1.5vw;
    position: relative;
    top: -10px;
  }

  .large_title_left {
    position: absolute;
    left: 0;
    top: 0;
    width: 35vw;
    height: 2vw;
  }

  .large_title_right {
    position: absolute;
    right: 0;
    top: 0;
    width: 35vw;
    height: 2vw;
  }

  .large_title_left_star {
    position: absolute;
    left: 10vw;
    top: 0.8vw;
    width: 15vw;
    height: 2vw;
    display: flex;
  }

  .large_title_right_star {
    position: absolute;
    right: 10vw;
    top: 0.8vw;
    width: 15vw;
    height: 2vw;
    display: flex;
  }
}

.box {
  position: absolute;
  width: 100vw;
  height: 90vh;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(3, 30%);
  grid-template-rows: repeat(3, 30%);
  row-gap: 3%;
  column-gap: 3%;
  justify-content: center;
}

.flex {
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  > div {
    flex: 1;
  }
}

.title {
  color: white;
  font-size: 22px;
  line-height: 28px;
  padding: 20px;
}

.user_counter {
  color: #fff;
  font-size: 26px;

  > div:nth-child(2) {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 5px;
  }
}
</style>
