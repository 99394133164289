<template>
    <div id="assets_by_type-container" :style="{ width, height }"></div>
</template>

<script>
import { Chart, registerInteraction } from "@antv/g2";

export default {
    name: "AssetsByTypeChart",
    props: ["width", "height"],
    data() {
        return {
            index: 0,
        };
    },
    created() {
        registerInteraction("auto-element-single-selected", {
            start: [
                { trigger: "afterrender", action: "element-single-selected:toggle" },
            ],
        });
    },
    async mounted() {
        const data = await this.getBlockchainData();
        // const data = [
        //     { item: "企业信息", count: 40, percent: 0.27 },
        //     { item: "员工信息", count: 21, percent: 0.49 },
        //     { item: "员工工资信息", count: 17, percent: 0.13 },
        //     { item: "简历管理", count: 13, percent: 0.11 },
        // ];
        const chart = new Chart({
            container: "assets_by_type-container",
            autoFit: true,
            height: 500,
            padding: [12,12,12,12]
        });
        chart.data(data);
        chart.interaction("element-single-selected");
        chart.scale("percent", {
            formatter: (val) => {
                val = val * 100 + "%";
                return val;
            },
        });
        chart.coordinate("theta", {
            radius: 0.75,
            innerRadius: 0.6,
        });
        chart.tooltip({
            showTitle: false,
            showMarkers: false,
            itemTpl:
                '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
        });
        let setAnnotation = () => {
            chart.annotation().clear(true);
            // 辅助文本
            chart
                .annotation()
                .text({
                    position: ["50%", "50%"],
                    content: `${data[this.index].item}`,
                    style: {
                        fontSize: 16,
                        fill: "#fff",
                        textAlign: "center",
                    },
                    offsetY: -20,
                })
                .text({
                    position: ["50%", "50%"],
                    content: `${data[this.index].percent * 100}%`,
                    style: {
                        fontSize: 24,
                        fill: "#fff",
                        textAlign: "center",
                    },
                    offsetY: 20,
                });
            chart.changeData(data);
        };
        let interval = chart
            .interval()
            .adjust("stack")
            .position("percent")
            .color("item")
            .label("percent", () => {
                return {
                    style: {
                        fill: "#fff",
                        fontSize: 16
                    },
                    content: (data) => {
                        return `${data.item}`;
                    },
                };
            })
            .tooltip("item*percent", (item, percent) => {
                percent = percent * 100 + "%";
                return {
                    name: item,
                    value: percent,
                };
            }).style({ shadowColor: 'rgba(255,255,255,0.5)', shadowOffsetX: 0, shadowOffsetY: 0 ,shadowBlur: 5});

        chart.legend(false)

        setAnnotation();
        chart.interaction("element-active");

        chart.render();

        interval.elements[this.index].setState("selected", true);
        setInterval(() => {
            interval.elements[this.index].setState("selected", false);
            if (this.index < interval.elements.length - 1) this.index++;
            else this.index = 0;
            interval.elements[this.index].setState("selected", true);
            setAnnotation();
        }, 5000);
    },
    methods: {
        async getBlockchainData() {
            try {
                let res = await (await fetch(process.env.VUE_APP_BASE_API + '/bigscreen/blockchain')).json();
                if (res.code == 200) {
                    console.log(res);

                    let total = res.data.by_type.reduce((pre, cur) => pre + cur.data, 0)
                    let values = res.data.by_type.map(item => {
                        return { item: item.type, data: item.data, percent: parseFloat((item.data / total).toFixed(2)) }
                    })

                    console.log('AssetsByType', values);
                    return values;
                } else {
                    console.error(res.msg);
                }
            } catch (e) {
                console.error(e);
            }
        },

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
